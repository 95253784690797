import React, { useContext, useEffect, useState } from 'react';
import s from './PageHeader.module.scss';
import PageHeaderDesktop from './elements/PageHeaderDesktop/PageHeaderDesctop';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import SignupFormContainer from '../../components/Forms/SignupForm/SignupFormContainer';
import Popup from '../../components/Popup/Popup';
import { useAuth } from '../../context/AuthContext';
import ForgotPasswordForm from '../../components/Forms/ForgotPasswordForm/ForgotPasswordForm';
import { LayoutContext } from '../../context/LayoutContext';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import CategoryThunks from '../../redux/category/thunks';
import useAdaptive from '../../hooks/useAdaptive';
import PageHeaderMobile from './elements/PageHeaderMobile/PageHeaderMobile';

const PageHeader = () => {
    const rootMenu = useAppSelector((state) => state?.category?.rootCategories);
    const dispatch = useAppDispatch();
    const { user, logout } = useAuth();
    const { isDesktop, isBigTablet, isTablet, isMobile } = useAdaptive();

    const { showLoginPopup, setShowLoginPopup } = useContext(LayoutContext);
    const [showRootMenu, setShowRootMenu] = useState<boolean>(false);
    const [popup, setPopup] = useState<string>('login');

    const loginPopup = (
        <LoginForm
            visible={showLoginPopup}
            setVisible={setShowLoginPopup}
            isPopup={true}
            switchPopup={setPopup}
        />
    );

    const signUpPopup = (
        <SignupFormContainer
            visible={showLoginPopup}
            setVisible={setShowLoginPopup}
            isPopup={true}
            switchPopup={setPopup}
        />
    );

    const forgotPasswordPopup = (
        <ForgotPasswordForm
            isPopup={true}
            setVisible={setShowLoginPopup}
            switchPopup={setPopup}
        />
    );

    const toggleRootMenu = () => {
        setShowRootMenu((prev) => !prev);
    };

    useEffect(() => {
        dispatch(CategoryThunks.getRootCategories());
    }, []);

    useEffect(() => {
        if (user) {
            setShowLoginPopup(false);
        }
    }, [user]);

    useEffect(() => {
        if (!showLoginPopup) {
            setPopup('login');
        }
    }, [showLoginPopup]);

    return (
        <>
            <header className={`${s.header}`}>
                <div className={`${s.header__top} container`}>
                    {isDesktop || isBigTablet ? (
                        <PageHeaderDesktop
                            showRootMenu={showRootMenu}
                            toggleRootMenu={toggleRootMenu}
                            toggleLoginPopup={() => setShowLoginPopup(true)}
                            rootMenu={rootMenu}
                        />
                    ) : isTablet || isMobile ? (
                        <PageHeaderMobile
                            user={user}
                            setShowLoginPopup={setShowLoginPopup}
                            logout={logout}
                            rootMenu={rootMenu}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </header>
            <Popup
                popup={
                    popup === 'login'
                        ? loginPopup
                        : popup === 'signUp'
                        ? signUpPopup
                        : forgotPasswordPopup
                }
                setVisible={setShowLoginPopup}
                visible={showLoginPopup}
            />
        </>
    );
};

export default PageHeader;
