
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import App from "next/app";
import { RootStore } from "../redux/store";
import { wrapper } from "../redux/storeCreator";
import "../styles/global.scss";
import '@splidejs/react-splide/css/core';
import { useEffect, useMemo, useState } from "react";
import Router, { useRouter } from "next/router";
import { CookiesProvider } from 'react-cookie';
import { parseCookies } from "universal-cookie/lib/utils";
import useAlert from "../hooks/useAlert";
import { AlertProvider } from "../context/AlertContext";
import { LayoutContext } from "../context/LayoutContext";
import NProgress from 'nprogress';
import UserThunks from "../redux/user/thunks";
import EchoProvider from "../context/EchoContext";
import { AuthProvider } from "../context/AuthContext";
import AlertPopup from "../components/Alert/Alert";
import Layout from "../layout/Layout";
import { AdminStore } from "../redux/admin/store";
import * as Sentry from "@sentry/react";
import AxiosActions from "../axios/axios";
import useTranslation from "next-translate/useTranslation";
Router.events.on('routeChangeStart', () => {
    NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
Sentry.init({
    dsn: "https://c6ba08d245144f30ad5e766ac805dac5@o1419479.ingest.sentry.io/6763405",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
});
const MyApp = ({ Component, pageProps }) => {
    const { t } = useTranslation('common');
    const [showFooter, setShowFooter] = useState<boolean>(true);
    const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false);
    const value = useMemo(() => ({ showFooter, setShowFooter, showLoginPopup, setShowLoginPopup }), [showFooter, showLoginPopup]);
    const router = useRouter();
    const { setAlert } = useAlert();
    const verifyEmail = async () => {
        try {
            const { id, hash, signature, verifyEmail } = router.query;
            const { status } = await AxiosActions.verifyEmail(id as string, hash as string, signature as string, verifyEmail as string);
            if (status) {
                setAlert(t("Your email was verified. Now you can login."), "success");
                setShowLoginPopup(true);
            }
        }
        catch (e) {
            setAlert(t("Something went wrong. Try again later."), "error");
        }
    };
    useEffect(() => {
        if (router.query?.verifyEmail === "Y") {
            verifyEmail();
        }
    }, [router]);
    return (<LayoutContext.Provider value={value}>
            <AlertProvider>
                <CookiesProvider>
                    <AuthProvider>
                        <EchoProvider>
                            <Layout>
                                <AlertPopup />
                                <Component {...pageProps}/>
                            </Layout>
                        </EchoProvider>
                    </AuthProvider>
                </CookiesProvider>
            </AlertProvider>
        </LayoutContext.Provider>);
};
MyApp.getInitialProps = wrapper.getInitialAppProps((store: RootStore & AdminStore) => async (appContext) => {
    const appProps = await App.getInitialProps(appContext);
    const cookie = appContext?.ctx?.req?.headers?.cookie;
    const { token } = parseCookies(cookie) as {
        token: string | undefined;
    };
    if (token) {
        await store.dispatch(UserThunks.getMe(token));
    }
    return { ...appProps };
});
const __Next_Translate__Page__19272024309__ = wrapper.withRedux(MyApp);

    export default __appWithI18n(__Next_Translate__Page__19272024309__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  