import { useState } from 'react';
import s from './PageHeaderMobile.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import PageHeaderLogo from '../Logo/PageHeader-Logo';
import { Icon } from '../../../../components/Icon/Icon';
import LangToggle from '../../../../components/LangToggle/LangToggle';
import PageHeaderRootMenu from '../RootMenu/PageHeader-RootMenu';

const PageHeaderMobile = ({ user, setShowLoginPopup, logout, rootMenu }) => {
    const { t } = useTranslation('common');
    const router = useRouter();

    const [show, setShow] = useState<boolean>(false);

    const handleClose = () => {
        setShow(false);
        // props.toggleRootMenu(false);
    };

    const handleAddButton = () => {
        user?.id ? router.push('/new-advert') : setShowLoginPopup(true);
    };

    const handleFavoritesClick = () => {
        user?.id ? router.push('/profile/favorites') : setShowLoginPopup(true);
    };

    const handleLoginClick = () => {
        handleClose();
        setShowLoginPopup(true);
    };

    const handleExpand = () => {
        setShow(true);
    };

    return (
        <>
            <div className={`${s.wrapper}`}>
                <PageHeaderLogo />
                <button
                    className={s.favoritesButton}
                    type="button"
                    onClick={handleFavoritesClick}
                >
                    <Icon
                        name={'like'}
                        size={20}
                        stroke={'#D8352B'}
                        color={'#D8352B'}
                    />
                </button>
                <button
                    className={s.addButton}
                    type="button"
                    onClick={handleAddButton}
                >
                    <Icon name={'add_circle'} size={24} />
                </button>
                <div className={s.langToggle}>
                    <LangToggle hideFlag={true} />
                </div>
                <button
                    className={s.burger}
                    type="button"
                    onClick={handleExpand}
                >
                    <Icon name={'burger'} size={22} color={'black'} />
                </button>
            </div>

            <div className={show ? `${s.nav} ${s.nav_show}` : `${s.nav}`}>
                <button
                    className={s.nav__close}
                    onClick={handleClose}
                    type="button"
                >
                    <Icon name={'cross'} size={16} stroke={'black'} />
                </button>

                {!!user?.id && (
                    <Link
                        className={s.profileMobile}
                        href={'/profile'}
                        onClick={handleClose}
                    >
                        <div className={s.profileMobile__info}>
                            <div className={s.profileMobile__name}>
                                {user.firstName} {user.lastName || ''}
                            </div>
                            <div className={s.profileMobile__email}>
                                {user.email}
                            </div>
                        </div>
                        <div className={s.profileMobile__image}>
                            {user.avatar ? (
                                <Image
                                    src={user.avatar}
                                    style={{ objectFit: 'cover' }}
                                    fill
                                    alt={'user avatar'}
                                />
                            ) : (
                                <div className={s.profileMobile__placeholder}>
                                    {user.firstName[0]}
                                    {user.lastName && user.lastName[0]}
                                </div>
                            )}
                        </div>
                    </Link>
                )}

                <nav className={s.nav__list}>
                    <li className={s.nav__menuMobile} onClick={handleClose}>
                        {rootMenu?.length > 0 && (

                            <PageHeaderRootMenu
                                rootMenu={rootMenu}
                                toggle={() => {
                                    // props.toggleRootMenu(false);
                                }}
                            />
                        )}
                    </li>

                    {!!user?.id ? (
                        <li className={`${s.nav__item} ${s.nav__item_signOut}`}>
                            <button
                                className={`${s.nav__button}`}
                                onClick={() => {
                                    handleClose();
                                    logout();
                                }}
                                type="button"
                            >
                                <span className={s.nav__text}>
                                    {t('Sign out')}
                                </span>
                            </button>
                        </li>
                    ) : (
                        <li className={s.nav__item}>
                            <button
                                className={`${s.nav__button}`}
                                onClick={handleLoginClick}
                                type="button"
                            >
                                <div className={s.nav__text}>{t('Login')}</div>
                            </button>
                        </li>
                    )}
                </nav>
            </div>
        </>
    );
};

export default PageHeaderMobile;
