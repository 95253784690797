import React, { useEffect, useState } from 'react';
import s from './PageHeaderDesktop.module.scss';
import { PageHeaderNavProps } from '../../PageHeaderTypes';
import CustomButton from '../../../../components/Button/CustomButton';
import { useAuth } from '../../../../context/AuthContext';
import PageHeaderProfile from '../Profile/PageHeader-Profile';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useUserEcho from '../../../../hooks/useUserEcho';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import UserActionCreator from '../../../../redux/user/actions';
import useTranslation from 'next-translate/useTranslation';
import PageHeaderLogo from '../Logo/PageHeader-Logo';
import LangToggle from '../../../../components/LangToggle/LangToggle';
import PageHeaderRootMenu from '../RootMenu/PageHeader-RootMenu';

const PageHeaderDesktop = (props: PageHeaderNavProps) => {
    const [showProfile, setShowProfile] = useState<boolean>(false);
    const auth = useAuth();
    const router = useRouter();
    const user = useAppSelector((state) => state.user.data);
    const dispatch = useAppDispatch();
    const userChannel = useUserEcho();

    const { t } = useTranslation('common');

    const echoUserUpdateHandler = ({ user }) => {
        dispatch(UserActionCreator.setUserData(user));
    };

    useEffect(() => {
        if (!userChannel) {
            return;
        }

        userChannel.listen('.user.update', echoUserUpdateHandler);

        return () => {
            userChannel.stopListening('.user.update', echoUserUpdateHandler);
        };
    }, [user, userChannel]);

    return (
        <>
            <div className={`${s.wrapper}`}>
                <PageHeaderLogo />
                <div className={`${s.nav}`}>
                    <nav className={s.nav__list}>
                        <li
                            className={`${s.nav__item} ${s.nav__item_category}`}
                        >
                            <button
                                className={`${s.nav__button} ${
                                    props.showRootMenu ? s.active : ''
                                }`}
                                onClick={() => props.toggleRootMenu()}
                                type="button"
                            >
                                <span className={s.nav__text}>
                                    {t('Category')}
                                </span>
                            </button>
                        </li>

                        {!!auth?.user?.id ? (
                            <li className={s.nav__item}>
                                <Link href={'/profile/favorites'}>
                                    <div className={s.nav__text}>
                                        {t('Favorites')}
                                    </div>
                                </Link>
                            </li>
                        ) : (
                            <li className={s.nav__item}>
                                <button
                                    className={s.nav__button}
                                    onClick={props.toggleLoginPopup}
                                >
                                    {t('Favorites')}
                                </button>
                            </li>
                        )}

                        {!!auth?.user?.id ? (
                            <li
                                className={`${s.nav__item} ${s.nav__item_profile}`}
                            >
                                <button
                                    className={`${s.nav__button}  ${
                                        showProfile ? s.active : ''
                                    }`}
                                    onClick={() => setShowProfile(!showProfile)}
                                    type="button"
                                    title={`${user?.unread_messages > 0 ?  t("You have unread messages") : ""}`}
                                >
                                    <div className={s.nav__text}>
                                        {t('Profile')}
                                    </div>
                                    {user?.unread_messages > 0 && (
                                        <div className={s.unread_messages} />
                                    )}
                                </button>
                            </li>
                        ) : (
                            <li className={s.nav__item}>
                                <button
                                    className={`${s.nav__button}`}
                                    onClick={props.toggleLoginPopup}
                                    type="button"
                                >
                                    <div className={s.nav__text}>
                                        {t('Login')}
                                    </div>
                                </button>
                            </li>
                        )}

                        {!!auth?.user?.id ? (
                            <li
                                className={`${s.nav__item} ${s.nav__item_signOut}`}
                            >
                                <button
                                    className={`${s.nav__button}`}
                                    onClick={() => {
                                        auth.logout();
                                    }}
                                    type="button"
                                >
                                    <span className={s.nav__text}>
                                        {t('Sign out')}
                                    </span>
                                </button>
                            </li>
                        ) : (
                            ''
                        )}

                        <li className={`${s.nav__item} ${s.nav__item_post}`}>
                            <CustomButton
                                title={t('Post Listing')}
                                clickAction={() => {
                                    !auth?.user?.id
                                        ? props.toggleLoginPopup()
                                        : router.push('/new-advert');
                                }}
                            />
                        </li>
                        <li className={`${s.nav__item} ${s.langToggle}`}>
                            <LangToggle />
                        </li>
                    </nav>
                    {showProfile && (
                        <div className={s.profile}>
                            <PageHeaderProfile setShow={setShowProfile} />
                        </div>
                    )}
                </div>
            </div>
            {props.rootMenu?.length > 0 && props.showRootMenu && (
                <PageHeaderRootMenu
                    rootMenu={props.rootMenu}
                    toggle={() => props.toggleRootMenu()}
                />
            )}
        </>
    );
};

export default PageHeaderDesktop;
